jQuery(document).ready(function ($) {
    var mySwiper = new Swiper('.news-section .swiper-container', {
        slideClass: 'swiper-slide',
        slidesPerView: 1,
        slidesPerColumn: 1,
        spaceBetween: 0,
        autoplay: {
            delay: 8000,
        },
        breakpointsInverse: true,
        speed: 2000,
        effect: 'fade',
        parallax: true,
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable : true,
        },
        fadeEffect: {
            // crossFade: true
        },

    });

    // https://masonry.desandro.com/
    // Masonry Actueel overzicht
    if ($('.post-items').length) {
        $('.post-items').imagesLoaded(function () {
            // init Masonry after all images have loaded
            $('.post-items').masonry({
                // options
                percentPosition: true,
                horizontalOrder: true,
                itemSelector: '.post-item',
                columnWidth: '.post-item'
            });
        });
    }
});

